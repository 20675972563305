// src/app/auth-config.ts

import {
  IPublicClientApplication,
  PublicClientApplication,
  LogLevel,
  InteractionType,
  Configuration,
} from '@azure/msal-browser';
import {
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  ProtectedResourceScopes,
} from '@azure/msal-angular';
import { environment } from 'src/environments/environment';

export const msalConfig: Configuration = {
  auth: {
    clientId: '3757d9ea-16ab-4ef6-8fbb-60504d8bf7df', // Application (client) ID : PGIByInteractive
    authority:
      'https://pgib2c.b2clogin.com/pgib2c.onmicrosoft.com/B2C_1_NewSignIn',
    knownAuthorities: ['pgib2c.b2clogin.com'],
    redirectUri: environment.redirectUri,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (logLevel: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (logLevel) {
          case LogLevel.Error:
            // console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            // console.debug(message);
            return;
          case LogLevel.Warning:
            // console.warn(message);
            return;
        }
      },
    },
  },
};

const msalInstance: IPublicClientApplication = new PublicClientApplication(
  msalConfig
);

export const guardConfig: MsalGuardConfiguration = {
  interactionType: InteractionType.Redirect,
  authRequest: {
    scopes: ['openid', 'profile'],
  },
};

export const interceptorConfig: MsalInterceptorConfiguration = {
  interactionType: InteractionType.Redirect,
  protectedResourceMap: new Map<string, Array<string>>([
    [
      'https://graph.microsoft.com/v1.0/me',
      [
        'https://pgib2c.onmicrosoft.com/inetactivelife/pgi/api/user.read',
        'https://pgib2c.onmicrosoft.com/inetactivelife/pgi/api/User.Read.All',
      ],
    ],
  ]),
};

// Initialize the msalInstance
msalInstance
  .initialize()
  .then(() => {
    console.log('MSAL initialized');
  })
  .catch((error) => {
    console.error('MSAL initialization failed', error);
  });

export { msalInstance };
